import '../styles/components/software-tile.scss'
import { navigate } from 'gatsby'
import React from 'react'
import { BsArrowRight } from 'react-icons/bs'

import { useLabelsAndInternationalization } from '../hooks/cms/use-labels-and-internationalization'
import type { Software } from '../hooks/cms/use-software'
import { useTags } from '../hooks/cms/use-tags'
import { useHrefMaker } from '../hooks/use-href-maker'
import { Button } from './button'
import { Image } from './image'
import { OverflowIndicator } from './overflow-indicator'
import { Tag } from './tag'

export interface SoftwareTileProps {
  alt?: boolean
  software: Software
  tagsDisabled?: boolean
  verticalPaddingDisabled?: boolean
  horizontalPaddingDisabled?: boolean
  buttonVariant?: 'primary' | 'secondary' | 'tertiary'
  wrappingDisabled?: boolean
  selectedTags?: { [key: string]: boolean }
}

export const SoftwareTile: React.FC<SoftwareTileProps> = ({
  alt,
  software,
  tagsDisabled = false,
  verticalPaddingDisabled = false,
  horizontalPaddingDisabled = false,
  buttonVariant = 'primary',
  wrappingDisabled = false,
  selectedTags,
}) => {
  const { table: tags } = useTags()
  const labels = useLabelsAndInternationalization()
  const makeHref = useHrefMaker()

  const checkIfTagSelected = (tagName: string) =>
    selectedTags ? selectedTags[tagName] : false

  return (
    <div
      className={`software-tile ${
        !verticalPaddingDisabled ? 'vertical-padding' : ''
      }  ${!horizontalPaddingDisabled ? 'horizontal-padding' : ''} ${
        wrappingDisabled ? 'no-tile-wrapping' : ''
      }`}
    >
      <div>
        {
          <Image
            path={
              alt ? software.media.previewImageAlt : software.media.previewImage
            }
          />
        }
      </div>
      <div>
        <span className="software-tile__name">{software.name}</span>
        <h4 className="software-tile__heading">{software.heading}</h4>
        <p className="software-tile__description">{software.description}</p>
        {!tagsDisabled && software.tags && (
          <div className="software-tile__tags">
            <OverflowIndicator>
              {software.tags.map(identifier => (
                <Tag
                  eventsDisabled
                  selected={checkIfTagSelected(identifier)}
                  key={identifier}
                >
                  {tags.get(identifier)?.title}
                </Tag>
              ))}
            </OverflowIndicator>
          </div>
        )}

        <div className="software-tile__cta">
          <Button
            variant={buttonVariant}
            icon={<BsArrowRight />}
            onClick={() =>
              navigate(makeHref(`/software/${software.identifier}/`))
            }
          >
            {labels.strings.learnMoreButton}
          </Button>
        </div>
      </div>
    </div>
  )
}
