import '../styles/templates/software.scss'

import { omit } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'

import { PAGE_NAME } from '../../config/cms'
import { Layout } from '../components/layout'
import { PageHeading } from '../components/page-heading'
import { PageSubheading } from '../components/page-subheading'
import { SoftwareTile } from '../components/software-tile'
import { Tag } from '../components/tag'
import { usePageData } from '../hooks/cms/use-page'
import { useSoftware } from '../hooks/cms/use-software'
import { useTags } from '../hooks/cms/use-tags'
import { win } from '../utils/monads'
import { PageComponentFactory } from '../utils/pages'

const TEMPLATE_NAME = PAGE_NAME.Software

const SoftwarePage = PageComponentFactory(() => {
  const { landingSection, strings } = usePageData(PAGE_NAME.Software)

  const tags = useTags()
  const software = useSoftware()

  const uniqueSoftwareTags = new Set(
    software.list.flatMap(element => element.tags)
  )

  const tagsToDisplay = tags.list.filter(tag =>
    uniqueSoftwareTags.has(tag.identifier)
  )

  const locationState = win.history.state

  const [selectedTags, setSelectedTags] = useState<Record<string, boolean>>({})

  const filteredSoftware = useMemo(
    () =>
      software.list.filter(
        software =>
          Object.keys(selectedTags).length === 0 ||
          Object.keys(selectedTags).every(x => software.tags?.includes(x))
      ),
    [software.list, selectedTags]
  )

  useEffect(() => {
    if (locationState?.tag) {
      setSelectedTags(currentSelectedTags => ({
        ...currentSelectedTags,
        [locationState?.tag]: true,
      }))
    }
  }, [locationState?.tag])

  return (
    <Layout
      variant="secondary"
      buttonVariant="primary"
      page={TEMPLATE_NAME}
      pathname="/software"
    >
      <main className="software-page">
        <section className="software-page__landing-section">
          <div className="container">
            <PageHeading>{landingSection.heading}</PageHeading>
            <PageSubheading>{landingSection.caption}</PageSubheading>
          </div>
        </section>

        <section className="software-page__list-section">
          <div className="software-page__list-section-tags">
            <div className="container">
              <div>
                <div>
                  <span>{strings.tagsLabel}:</span>
                  {tagsToDisplay.map(tag => (
                    <Tag
                      selected={selectedTags[tag.identifier]}
                      key={tag.identifier}
                      onClick={() =>
                        setSelectedTags(current =>
                          current[tag.identifier]
                            ? { ...omit(current, [tag.identifier]) }
                            : { ...current, [tag.identifier]: true }
                        )
                      }
                    >
                      {tag.title}
                    </Tag>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="container">
              <div className="software-page__list-section-grid">
                {filteredSoftware.map(software => (
                  <SoftwareTile
                    key={software.identifier}
                    software={software}
                    selectedTags={selectedTags}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
})

export default SoftwarePage
